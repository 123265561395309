var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"APP名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"版本"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'version',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'version',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入1.0.0这种格式"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"ios链接"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'iosUrl',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'iosUrl',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"Android安装包"}},[_c('FileUpload',{on:{"uploaded":_vm.uploadedAndroid}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.androidUrl ? "已上传" : "上传")+" ")])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"Windows安装包"}},[_c('FileUpload',{on:{"uploaded":_vm.uploadedWindows}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.windowsUrl ? "已上传" : "上传")+" ")])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"macOS安装包"}},[_c('FileUpload',{on:{"uploaded":_vm.uploadedMacOS}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.macOsUrl ? "已上传" : "上传")+" ")])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"harmonyOS安装包"}},[_c('FileUpload',{on:{"uploaded":_vm.uploadedHarmonyOS}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(_vm.harmonyOsUrl ? "已上传" : "上传")+" ")])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"备注","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remark',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入！',
                    },
                  ],
                },
              ]),expression:"[\n                'remark',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入！',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.androidUrl)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }